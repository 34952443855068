import { useEffect } from "react";
import { useState } from "react"; 
import { Auth } from "aws-amplify";
import { Navigate } from 'react-router-dom';

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/profile';
  console.log("from:", from);
  useEffect(() => {
    const handleRedirect = async () => {
      if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  };
  handleRedirect();
  }, [route, navigate, from]);
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let handleSubmit = async function (event) {
    event.preventDefault();
    try {
      let response = await Auth.signIn(email, password);
      console.log("auth response", response);
      if (response.signInUserSession.accessToken) {
      //const url = "https://billing.stripe.com/p/login/cN2bK5fsz7QwcYU5kk";
      //const prefilledEmail = encodeURIComponent(email);
      //window.location.href = `${url}?prefilled_email=${prefilledEmail}`;
      window.location.href = "/profile";
      console.log("starting the redirect navigation");
      }
    } catch (e) {
      console.error(e);
    }
    console.log("Finished the submit event");
  };
  
  return (
    <>
  <div class="login-wrap">
    <div data-animation="default" data-collapse="medium" data-duration="500" data-easing="ease-in" data-easing2="ease-out" role="banner" class="navbar-sign-up w-nav">
      <div class="container nav-sign-up">
      <a href="/" className="brand signedin w-inline-block"><img src={require("../images/SevontLogo2-p-500.png")} className ="logo" /></a>
        <div class="other-option-link">Don't have an account? <a href="/signup" class="login-link">Sign Up</a>
        </div>
      </div>
    </div>
    <div class="section green wf-section">
      <div data-w-id="80fb9fd3-dd68-2679-0102-36f015384f43" class="container">
        <div class="sign-up-card">
          <div class="sign-up-heading">
            <h1 class="text-center">Sign In</h1>
            <p class="text-dark-gray">Please fill your email and password to login.</p>
          </div>
          <div class="w-form">
            <form id="sign-in" onSubmit={handleSubmit} name="wf-form-Sign-In" data-name="Sign In" method="get" class="form---sign-up">
              <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} class="text-field w-input" maxlength="256" name="Email" data-name="Email" placeholder="Email" id="email-sign-in" required=""/>
              <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} class="text-field w-input" maxlength="256" name="Password" data-name="Password" placeholder="Password" id="password-sign-in" required=""/>
              <input type="submit" value="Sign In" data-wait="Please wait..." class="button w-button"/></form>
            <div class="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
          <p class="text-dark-gray">Forgot your password? <a href="/forgotpassword">Reset it here.</a>
          </p>
        </div>
      </div>
    </div>
    <div class="section white wf-section">
      <div class="container">
        <div class="footer-sign-up-content">
        <p>Copyright 2023 © Function Factories.</p>
        </div>
      </div>
    </div>
  </div>
  <script src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=644aa0afde40afbc083e12da" type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script>
  <script src="js/webflow.js" type="text/javascript"></script>
  </>
  );
}