import { useEffect } from "react";
import { useState } from "react"; 
import { Auth } from "aws-amplify";
import handleSignOut from './signOut';
import { Hub } from "aws-amplify";
import * as React from 'react';
import { SiteSetupForm } from "./sitesetupform";

export function Profile() {

  console.log("Profile is rendering");
  const [authStatus, setAuthStatus] = useState("");
  const [group, setGroup] = useState("");
  const [email, setEmail] = useState("");
  const [websites, setWebsites] = useState("");
  const listener = (data) => {
    setAuthStatus(data.payload.event);
  };
  console.log(authStatus);
  Hub.listen("auth", listener);

  const getUserDetails = async () => {
    const session = await Auth.currentSession();
    const user = session.getIdToken().payload;
    const idToken = session.getIdToken().getJwtToken();
    localStorage.setItem('userEmail', user.email);
    console.log(user.email);
    const user_details = {
      action: "check_user",
      email: user.email
    }
    try {
      const response = await fetch(
        `https://74zyj58v4i.execute-api.us-east-1.amazonaws.com/prod/users`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
            Authorization: idToken,
          },
          body: JSON.stringify(user_details),
        }
      );
      console.log(response);
      const searchResult = await response.text();
      const data = JSON.parse(searchResult);
      const usergroup = data.usergroup.S;
      const website = data.websites.M;
      setGroup(usergroup);

      console.log(usergroup);
      localStorage.setItem('companyName', website['companyname']);
      setWebsites(website);
    } catch (ex) {
      console.error(ex);
    }
  }

  function WebsiteBubble({ website }) {
    return (
      <div className="combine-feature7_item">
        <div className="domain-pic">
          <img src="images/domain.png" loading="lazy" width={44} alt="" />
        </div>
        <h3 className="combine-heading-style-h5 sourcewebsite">{website.name}</h3>
        <a href="#" className="button w-button">Details</a>
      </div>
    );
  }

  useEffect(() => {
    getUserDetails();
  }, []);


  return (
    <>
      <div>
        <div data-animation="default" data-collapse="medium" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="navbar signed-in w-nav">
          <a href="/" className="brand signedin w-inline-block"><img src={require("../images/SevontLogo1-p-500.png")} className ="logo" /></a>
          <nav role="navigation" className="nav-menu w-nav-menu">
            <div className="nav-link-wrap">
              <div className="nav-button-wrap">
                <a onClick={handleSignOut} className="button secondary small signout w-button">Sign Out</a>
              </div>
            </div>
          </nav>
          <div className="menu-button w-nav-button">
            <div className="menu-bar-1" />
            <div className="menu-bar-2" />
            <div className="menu-bar-3" />
          </div>
        </div>
        { group === "newusers" && Object.keys(websites).length === 0 ? (
          <div style={{ paddingTop: '100px', paddingBottom: '100px', backgroundColor: 'white'}}>
            <stripe-pricing-table
              pricing-table-id="prctbl_1N9az1F80JjQl8zSI6t91BJA"
              publishable-key="pk_test_51KPBOrF80JjQl8zS1S8jHOwVt9LlDZILwPT3COafCN7eOwZxhXfod78fLSJABoZBoXpoP51qD3GC5JjWc33LrkTh00PWHx6Cvp">
            </stripe-pricing-table>
          </div>
          ) : group === "paidusers" && Object.keys(websites).length === 0 ? (
        <div>
          <section className="combine-section_feature7">
          <SiteSetupForm />
          </section>
        </div>
        ) : group === "paidusers" && Object.keys(websites).length >= 1 ? (
          <div className="section-2 profile wf-section">
          <section className="combine-section_feature7">
            <div className="combine-padding-global-2">
              <div className="combine-container-large-2">
                <div className="combine-padding-section-medium-2">
                  <div className="combine-feature7_component">
                    <div className="combine-feature7_content-sticky">
                      <div id="source-company-name" className="combine-text-size-regular-2 companyname">{localStorage.getItem('companyName')}</div>
                      <div id="source-user-name" className="text-block-2 username">{localStorage.getItem('userEmail')}</div>
                      <a id="add-website" href="/sitesetupform" className="link">Add a website</a>
                      <a id="manage-plan" href={`https://billing.stripe.com/p/login/cN2bK5fsz7QwcYU5kk?prefilled_email=${localStorage.getItem('userEmail')}`} className="link-2">Manage Plan</a>
                    </div>
                    <div className="combine-feature7_wrapper">
                      <div className="combine-feature7_item">
                        <div className="domain-pic"><img src="images/domain.png" loading="lazy" width={44} alt="" /></div>
                        <h3 id="source-website" className="combine-heading-style-h5 sourcewebsite">www.website.com</h3>
                        <a id="file-details-view" href="file-list.html" className="button w-button">Details</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        ) : null}
        <div className="section features wf-section">
          <div className="container">
            <div className="footer-content">
              <div className="footer-line" />
              <div className="footer-bottom">
                <div id="w-node-b479ba1a-a1e7-5fb6-825a-62674de37573-bb3bdee6" className="footer-logo-and-social">
                  <div className="footer-logo-wrap">
                    <a href="/" className="brand w-inline-block"><img src={require("../images/SevontLogo2-p-500.png")}/></a>
                    <p>Copyright 2023 © Function Factories.</p>
                  </div>
                  <div className="footer-social-wrap">
                    <a href="#" className="social-link w-inline-block"><img src={require("../images/Facebook.png")} loading="lazy" alt="" /></a>
                    <a href="#" className="social-link w-inline-block"><img src={require("../images/Instagram.png")} loading="lazy" alt="" /></a>
                    <a href="#" className="social-link w-inline-block"><img src={require("../images/Youtube.png")} loading="lazy" alt="" /></a>
                    <a href="#" className="social-link w-inline-block"><img src={require("../images/Twitter.png")} loading="lazy" alt="" /></a>
                    <a href="#" className="social-link w-inline-block"><img src={require("../images/LinkedIn.png")} loading="lazy" alt="" /></a>
                  </div>
                </div>
                <div className="footer-link-wrap">
                  <div id="w-node-b479ba1a-a1e7-5fb6-825a-62674de37589-bb3bdee6" className="footer-link-column-wrap">
                    <div className="text-700">Product</div>
                    <a href="/" className="footer-link">Home</a>
                    <a href="#" className="footer-link">Features</a>
                    <a href="#" className="footer-link">Pricing</a>
                  </div>
                  <div id="w-node-b479ba1a-a1e7-5fb6-825a-62674de37592-bb3bdee6" className="footer-link-column-wrap">
                    <div className="text-700">Resources</div>
                    <a href="contact.html" className="footer-link">Support</a>
                    <a href="#" className="footer-link">Blog</a>
                    <a href="#" className="footer-link">Privacy Policy</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>

  )
}