import logo from './logo.svg';
import './App.css';
import './css/webflow.css';
import './css/normalize.css';
import './css/webflow-override.css';
import { Amplify } from 'aws-amplify';
import { Authenticator, Image} from '@aws-amplify/ui-react';
import Home from "./home";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { RequireAuth } from './RequireAuth';
import { Login } from './components/LogIn';
import { ForgotPassword } from './components/forgotpassword';
import SignUp from "./components/signup";
import { SiteSetupForm } from './components/sitesetupform';
import { Profile } from './components/profile';
import { API } from '@aws-amplify/api'

import awsExports from './aws-exports';
Amplify.configure(awsExports);
API.configure(awsExports)

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <Login /> } />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/sitesetupform" element={<SiteSetupForm />} />
        <Route 
        path="/profile" 
        element={
        <RequireAuth>
          <Profile />
        </RequireAuth>
      } 
      />
      <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default function App({Component, pageProps}) {
return ( 
    
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  
    );
  }
  

