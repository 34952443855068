import React, { useState } from "react";
import { Storage } from 'aws-amplify';
import { Auth } from "aws-amplify";
import awsExports from '../aws-exports';

export function SiteSetupForm() {
const [fileStatus, setFileStatus] = useState(false);
const [filename, setFileName] = useState("");
const s3BucketName = awsExports.aws_user_files_s3_bucket;
console.log(s3BucketName);
const [formData, setFormData] = useState({
  companyName: "",
  websiteDomain: "",
  installationNeeded: ""
});
const Company = formData.companyName;


function upLoadFile(e) {
  const fileInput = e.target;
  const fileNames = [...fileInput.files].map(file => file.name).join(", ");
  document.getElementById("fileStatus").textContent = fileNames || "No file chosen";
}

async function uploadFiles(e) {
  e.preventDefault();
  
  const fileInput = document.getElementById("fileInput");
  const file = fileInput.files[0];
  
  const progressBar = document.createElement("progress");
  progressBar.max = file.size;
  progressBar.value = 0;
  document.getElementById("uploadForm").appendChild(progressBar);
  
  try {
    const { key } = await Storage.put(file.name, file, {
      level: "private",
      progressCallback: progress => {
        progressBar.value = progress.loaded;
      }
    });
    setFileStatus(true);
    console.log(key)
    setFileName(file.name);
  } catch (error) {
    console.log("Error uploading file: ", error);
  } finally {
    document.getElementById("uploadForm").removeChild(progressBar);
  }
}
const addData = (filename, s3path) => {
  return {
    ...formData,
    fileName: filename,
    s3Path: s3path
  };
};

async function submitForm() {
    const session = await Auth.currentSession();
    const user = session.getIdToken().payload;
    const idToken = session.getIdToken().getJwtToken();
    console.log(user['cognito:username']);
    const s3path = "s3://" + s3BucketName + "/" + Company + "/" + user['cognito:username'] + "/" + filename;
    //const s3path = "s3://" + s3BucketName + "/private/us-east-1:" + user['cognito:username'] + "/" + filename;
    const updatedFormData = addData(filename, s3path);
    const user_details = {
    action: "update_user",
    email: user.email,
    websites: updatedFormData
  }
  try {
    const response = await fetch(
      `https://74zyj58v4i.execute-api.us-east-1.amazonaws.com/prod/users`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: idToken,
        },
        body: JSON.stringify(user_details),
      }
    );
    window.location.href = "/profile";
    if (!response.ok) {
      console.log(response);
    }
  } catch (ex) {
    console.error(ex);
  }
}
const handleFormSubmit = async (event) => {
  event.preventDefault();
  await submitForm();
};


    return (
        <>
        <div className="back-to-profile-wrap">
          <a href="/profile" className="back-to-profile">Back to Profile</a>
        </div>
      <div className="section-2 wf-section">
          <div className="form-block w-form">
            <form id="wf-form-Form-1" name="wf-form-Form-1" data-name="Form 1" onSubmit={handleFormSubmit}>
              <div className="sysf-input-with-label"><label htmlFor="company-name" className="sysf-input-title">Company Name</label>
                <input type="text" className="sysf-input sysf-medium w-input" maxLength="256" name="company-name" data-name="company-name" placeholder="ACME Inc" id="company-name" required="" value={formData.companyName} onChange={(e) => setFormData({ ...formData, companyName: e.target.value })} /></div>
              <div className="sysf-input-with-label"><label htmlFor="website-domain" className="sysf-input-title">Website Domain</label>
                <p className="sysf-input-description">If you'd like your Sevont to be trained on the information on your website, please prove the domain.</p>
                <input type="text" className="sysf-input sysf-medium w-input" maxLength="256" name="website-domain" data-name="website-domain" placeholder="https://acme.com" id="website-domain" value={formData.websiteDomain} onChange={(e) => setFormData({ ...formData, websiteDomain: e.target.value })}/>
              </div>
              <div className="html-embed w-embed">
                <div className="wrapperUpload">
                  <h5>Upload File(s)</h5>
                  <h6>If you'd like your Sevont to be trained on specific files, select yes. Select up to five.</h6>
                  <br></br>
                  <form id="uploadForm">
                    <label htmlFor="fileInput" className="choose-files-btn">Choose Files</label>
                    <input type="file" onChange={upLoadFile} id="fileInput" name="files[]" required="" multiple="" />
                    <br></br>
                    <span id="fileStatus">No file chosen</span>
                    <br></br>
                    <br></br>
                    <button type="button" onClick={uploadFiles}>Upload</button>
                    {fileStatus ? "File uploaded successfully" : ""}
                  </form>
                  <div id="fileList"></div>
                </div>
              </div>
              <div className="sysf-input-with-label">
                <label htmlFor="Password" className="sysf-input-title">Do you need installation?</label>
                <p className="sysf-input-description">If you'd like us to incorporate your Sevont on your website, select yes (complimentary!).</p><label className="sysf-radio-button-field w-radio">
                  <input type="radio" id="Yes" name="Radio-buttons" value="Yes" data-name="Radio buttons" checked={formData.installationNeeded === "Yes"} onChange={() => setFormData({ ...formData, installationNeeded: "Yes" })} />
                  <span className="sysf-radio-button-label w-form-label" htmlFor="Yes">Yes</span>
                </label><label className="sysf-radio-button-field w-radio">
                  <input type="radio" id="No" name="Radio-buttons" value="No" data-name="Radio buttons" checked={formData.installationNeeded === "No"} onChange={() => setFormData({ ...formData, installationNeeded: "No" })} />
                  <span className="sysf-radio-button-label w-form-label" htmlFor="No">No</span>
                </label>
              </div>
              <input type="submit" value="Submit" data-wait="Please wait..." className="sysf-button sysf-medium w-button" />
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
        </>
        );
      }