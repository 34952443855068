import { Auth } from 'aws-amplify';

async function handleSignOut() {
    try {
        await Auth.signOut();
        console.log("signed out");
        window.location.href = "/";
      } catch (error) {
        console.log("error signing out: ", error);
      }
};
export default handleSignOut;
