import './css/webflow.css';
import './css/normalize.css';
import './css/bot-b59bdc.webflow.css';
import React, { useState } from 'react';


function Home() {
  return (
    <><div data-w-id="ba6b8229-b5f8-24de-b5c0-5bdd4113bbf6" data-animation="default" data-collapse="medium" data-duration="500" data-easing="ease-in" data-easing2="ease-out" role="banner" class="navbar w-nav">
      <div class="container nav">
      <a href="/" className="brand signedin w-inline-block"><img src={require("./images/SevontLogo2-p-500.png")} className ="logo" /></a>
        <nav role="navigation" class="nav-menu w-nav-menu">
          <div class="nav-link-wrap">
            <a href="#feature-section" class="nav-link w-nav-link">Features</a>
            <a href="#pricing-section" class="nav-link w-nav-link">Pricing</a>
            <a href="sign-in.html" class="nav-link w-nav-link">Support</a>
            <a href="#" class="nav-link w-nav-link">Blog</a>
            <div class="nav-button-wrap">
              <a href="./login" class="button secondary small w-button">Sign in</a>
              <a href="#pricing-section" class="button small w-button">Get Started</a>
            </div>
          </div>
        </nav>
        <div class="menu-button w-nav-button">
          <div class="menu-bar-1"></div>
          <div class="menu-bar-2"></div>
          <div class="menu-bar-3"></div>
        </div>
      </div>
    </div><div class="section wf-section">
        <div data-w-id="cba4bdf1-8d17-b58b-67f2-52fa2f59a59c" class="container">
          <div class="hero-content">
            <div id="w-node-aeb9cdd4-3346-de95-afe5-67dbf4ed6138-b53e12e6" class="hero-heading">
              <h1 class="heading-xl">Unleash AI Sales Enablement to Effortlessly <span class="text-span">Guide Them to Their Perfect Purchase</span></h1>
              <p class="paragraph-large text-dark-gray">Sevont makes websites smarter to create an easy and simple way for a customer to have confidence that they’re purchasing the right product through advanced AI technology.</p>
              <div class="hero-button-wrap">
                <a href="#pricing-section" class="button w-button">Boost Sales Now</a>
                <a href="#" class="button secondary w-inline-block w-lightbox">
                  <div>Give it a Try</div>

                </a>
              </div>
              <div></div>
            </div>
            <div id="w-node-e290fca6-923a-4576-453f-129d42ab8c8d-b53e12e6">Visual Demo</div>
            <div id="w-node-ca073e67-bfb8-d9ae-2fd9-ae58b2e23368-b53e12e6"></div>
          </div>
        </div>
      </div><div class="section features wf-section">
        <div data-w-id="be911688-252b-9666-674a-a94355001deb" class="container">
          <div class="stats-content">
            <h2 class="home-section-heading">Trusted by Zillions</h2>
            <div class="stat-wrap">
              <div id="w-node-d887e07c-584d-ca81-9b7d-29680d3b830b-b53e12e6" class="stat-card">
                <div>Securing over</div>
                <div class="text-h2 text-green-light">5 Zillion</div>
                <div>users worldwide</div>
              </div>
              <div id="w-node-_74a4073e-3092-b271-bb79-691243f59841-b53e12e6" class="stat-card">
                <div>App rating of</div>
                <div class="text-h2 text-green-light">5.0</div><img src={require("./images/Stars.svg")} loading="lazy" alt="" />
              </div>
              <div id="w-node-_2d8e2cae-107b-6e16-e3a0-5ad1079045e3-b53e12e6" class="stat-card">
                <div>Awarded</div>
                <div class="award-wrap"><img src={require("./images/Laurel-Left.svg")} loading="lazy" alt="" />
                  <div class="text-h2 text-green-light">2022</div><img src={require("./images/Laurel-Right.svg")} loading="lazy" alt="" />
                </div>
                <div>app of the year</div>
              </div>
            </div>
          </div>
        </div>
      </div><div class="live-demo-section wf-section"></div><section class="combine-section_cta1">
        <div class="combine-padding-global">
          <div class="combine-container-small">
            <div class="combine-padding-section-large">
              <div class="combine-text-align-center">
                <h2 class="combine-heading-style-h2">Unlock the True Potential of Your Website by Incorporating Your Sevont!</h2>
                <div class="combine-space-medium"></div>
                <div class="combine-text-size-regular">Enhance your customer experience, boost their confidence, and increase sales. Don&#x27;t wait – experience the Sevont difference now.</div>
                <div class="combine-space-medium"></div>
                <div class="combine-button-wrapper">
                  <a href="#pricing-section" class="combine-button w-button">Get Started</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section><div class="section setup wf-section">
        <div data-w-id="8e1f079e-7ebe-4be7-a55d-53660d9166b4" class="container">
          <div class="home-feature-content">
            <h2 id="w-node-af130eb2-f8a4-1717-280e-078c4c32c154-b53e12e6" class="home-section-heading">Incredibly Simple Setup</h2>
            <div class="home-feature-wrap">
              <div class="home-feature"><img src={require("./images/Home-Feature-1.png")} loading="lazy" id="w-node-_538d8f8f-9ca7-b050-30a8-746f2daa6887-b53e12e6" alt="" class="home-feature-image" />
                <div id="w-node-e386d106-047e-5c8a-8ce9-8ed5f8bff31e-b53e12e6" class="home-feature-detail">
                  <h3>Select Tier and Sign Up</h3>
                  <p>Select yo. r preferred tier of Standard, Premier, or Enterprise. No long term commitment. </p>
                </div>
              </div>
              <div class="home-feature">
                <div id="w-node-_9e4e066d-405d-b901-c506-507f0d8b19bd-b53e12e6" class="home-feature-detail">
                  <h3>Upload Your Product/Service Documentation<span class="text-green"></span></h3>
                  <p>Provide a PDF, or simply enter your website domain and we can use this to train your Sevont! The data is fed into an engine that mixes and stores securely for future usage. We use GPT technologies to allow a very conversational experience.</p>
                </div><img src={require("./images/Home-Feature-2.png")} loading="lazy" id="w-node-_9e4e066d-405d-b901-c506-507f0d8b19bc-b53e12e6" alt="" class="home-feature-image" />
              </div>
              <div class="home-feature"><img src={require("./images/Home-Feature-3.png")} loading="lazy" id="w-node-_57a92d7d-5b66-69de-d467-2d3cc4c0045f-b53e12e6" alt="" class="home-feature-image" />
                <div id="w-node-_57a92d7d-5b66-69de-d467-2d3cc4c00460-b53e12e6" class="home-feature-detail">
                  <h3><span class="text-green">Plug It In!</span></h3>
                  <p>We are available to install the bot into your website for you as a complimentary service, or just take the HTML, CSS, and JS to do it yourself.</p>
                </div>
              </div>
            </div>
            <a id="w-node-_4161c9ba-d1e6-df4b-e34f-5cf5d379215a-d379215a" data-w-id="4161c9ba-d1e6-df4b-e34f-5cf5d379215a" href="#" class="arrow-link-wrap w-inline-block">
              <div class="text-500">Explore More Features</div><img src={require("./images/Right-Arrow-Green.svg")} loading="lazy" alt="" class="link-arrow" />
            </a>
          </div>
        </div>
      </div><div id="feature-section" class="section features wf-section">
        <div data-w-id="f7de92f2-349f-e46b-b3f8-53b4a3e4174a" class="compare-content">
          <div class="home-section-heading side-padding">
            <h2>Why Choose <span class="text-green-light">Sevont</span>?</h2>
            <p>A conversational product/service specialist built for your <br></br>business objectives.<br></br></p>
          </div>
          <div class="table-scroll-wrap">
            <div class="table-wrap">
              <div class="table-space"></div>
              <div class="table">
                <div class="table-highlight-wrap">
                  <div id="w-node-_42fb7dfa-ce9d-14bc-5f00-358a54505eea-b53e12e6" class="highlight"></div>
                </div>
                <div class="table-row">
                  <div id="w-node-_06ba68dc-4444-5981-b364-75966bf96a05-b53e12e6" class="table-block">
                    <div class="text-green">Feature</div>
                  </div>
                  <div id="w-node-_06ba68dc-4444-5981-b364-75966bf96a06-b53e12e6" class="table-block">
                    <div class="text-white">Sevont</div>
                  </div>
                  <div id="w-node-_06ba68dc-4444-5981-b364-75966bf96a07-b53e12e6" class="table-block">
                    <div class="text-green-dark">Others</div>
                  </div>
                </div>
                <div id="w-node-d362b4d9-bac4-b692-7aed-7683f0f8b0b5-b53e12e6" class="table-line"></div>
                <div class="table-row">
                  <div id="w-node-c0b39bf9-338e-464e-e461-61185172ab06-b53e12e6" class="table-block">
                    <div>Made to Answer Questions Specifically for Your Business</div>
                  </div>
                  <div id="w-node-c0b39bf9-338e-464e-e461-61185172ab07-b53e12e6" class="table-block"><img src={require("./images/Tick-White.png")} loading="eager" alt="" /></div>
                  <div id="w-node-c0b39bf9-338e-464e-e461-61185172ab08-b53e12e6" class="table-block"><img src={require("./images/Cross-Dark-Gray.png")} loading="eager" alt="" /></div>
                </div>
                <div id="w-node-_21dd3e95-4ba2-a2be-1c02-7c2f780aa2d2-b53e12e6" class="table-line"></div>
                <div class="table-row">
                  <div id="w-node-d63be93d-de41-7859-9ef0-f1e6ef4e01f2-b53e12e6" class="table-block">
                    <div>Consistent Branding <br></br>and Messaging</div>
                  </div>
                  <div id="w-node-d63be93d-de41-7859-9ef0-f1e6ef4e01f3-b53e12e6" class="table-block"><img src={require("./images/Tick-White.png")} loading="eager" alt="" /></div>
                  <div id="w-node-d63be93d-de41-7859-9ef0-f1e6ef4e01f4-b53e12e6" class="table-block"><img src={require("./images/Cross-Dark-Gray.png")} loading="eager" alt="" /></div>
                </div>
                <div id="w-node-_35d56819-43ed-60ff-db8e-a255dd61a552-b53e12e6" class="table-line"></div>
                <div class="table-row">
                  <div id="w-node-_2eb4a6c1-ffbb-6731-a880-9202b1894a2a-b53e12e6" class="table-block">
                    <div>Complimentary Installation</div>
                  </div>
                  <div id="w-node-_2eb4a6c1-ffbb-6731-a880-9202b1894a2b-b53e12e6" class="table-block"><img src={require("./images/Tick-White.png")} loading="eager" alt="" /></div>
                  <div id="w-node-_2eb4a6c1-ffbb-6731-a880-9202b1894a2c-b53e12e6" class="table-block"><img src={require("./images/Cross-Dark-Gray.png")} loading="eager" alt="" /></div>
                </div>
                <div id="w-node-e32a40c4-33f4-e2c6-8051-a0c0a59dd915-b53e12e6" class="table-line"></div>
                <div class="table-row">
                  <div id="w-node-_9fa38b31-07da-6da2-5542-6daff7b1a172-b53e12e6" class="table-block">
                    <div>Continuous Improvement Efforts on Your Behalf</div>
                  </div>
                  <div id="w-node-_9fa38b31-07da-6da2-5542-6daff7b1a173-b53e12e6" class="table-block"><img src={require("./images/Tick-White.png")} loading="eager" alt="" /></div>
                  <div id="w-node-_9fa38b31-07da-6da2-5542-6daff7b1a174-b53e12e6" class="table-block"><img src={require("./images/Cross-Dark-Gray.png")} loading="eager" alt="" /></div>
                </div>
              </div>
              <div class="table-space"></div>
            </div>
          </div>
          <div class="side-padding">
            <a href="#pricing-section" class="button w-button">Close More Deals Now!</a>
          </div>
        </div>
      </div><div id="pricing-section" class="section pricing wf-section">
        <div data-w-id="37d96964-f250-6b10-b9ad-a78a40cd83de" class="container">
          <div class="home-plan-content">
            <div class="home-section-heading">
              <h2>Put Your Sevont to Work</h2>
              <p class="text-dark-gray">Select Your Subscription Tier</p>
            </div>
            <div class="plan-wrap">
              <div class="toggle-wrap">
                <div>Yearly</div>
                <div data-w-id="44014b2b-8f9c-fe53-9b5c-ce58283ff781" class="toggle-button">
                  <div class="toggle-ball"></div>
                </div>
                <div>Monthly</div>
              </div>
              <div style={{display:'grid'}} class="plan-card-wrap yearly">
                <div id="w-node-d3a0f9ca-c499-5322-f3be-48790b0f59ae-b53e12e6" class="plan-card popular">
                  <div class="popular-tag-wrap">
                    <div class="popular-tag">
                      <div>Most Popular</div>
                      <div class="bubble-point"></div>
                    </div>
                  </div>
                  <div class="plan-card-heading">
                    <div class="plan-heading text-large text-500">Standard</div>
                    <div class="text-h2">$47.00</div>
                    <div class="text-small text-dark-gray">per month<br></br></div>
                  </div>
                  <a href="/signup" class="button w-button">Start for Free</a>
                  <div class="tick-list w-richtext">
                    <ul role="list">
                      <li>Up to 5,000 messages/month</li>
                      <li>Utilize any data to teach the model</li>
                      <li>Continuous enhancement</li>
                      <li>Mobile friendly</li>
                    </ul>
                  </div>
                </div>
                <div id="w-node-_12f35399-fe7a-f313-c9d7-3df24922242c-b53e12e6" class="plan-card">
                  <div class="plan-card-heading">
                    <div class="plan-heading text-large text-500">Premium</div>
                    <div class="text-h2">$175.00</div>
                    <div class="text-small text-dark-gray">per month<br></br></div>
                  </div>
                  <a href="/signup" class="button secondary w-button">Start for Free</a>
                  <div class="tick-list w-richtext">
                    <ul role="list">
                      <li>Up to 20,000 messages/month</li>
                      <li>Utilize any data to teach the model</li>
                      <li>Continuous enhancement</li>
                      <li>Mobile friendly</li>
                    </ul>
                  </div>
                </div>
                <div id="w-node-a839b23f-519c-3e7c-731b-08a1985a17c3-b53e12e6" class="plan-card">
                  <div class="plan-card-heading">
                    <div class="plan-heading text-large text-500">Enterprise</div>
                    <div class="text-h2">Contact Us</div>
                    <div class="text-small text-dark-gray">custom pricing<br></br></div>
                  </div>
                  <a href="contact.html" class="button secondary w-button">Contact Us</a>
                  <div class="tick-list w-richtext">
                    <ul role="list">
                      <li>Message bundles</li>
                      <li>Discounted pricing packages</li>
                      <li>Utilize any data to teach the model</li>
                      <li>Continuous enhancement</li>
                      <li>Mobile friendly</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div style={{display:'none'}} class="plan-card-wrap monthly">
                <div id="w-node-_4ff2cea1-00d7-f67e-15b4-82f383b0db0a-b53e12e6" class="plan-card">
                  <div class="plan-card-heading">
                    <div class="plan-heading text-large text-500">Free</div>
                    <div class="text-h2">$0</div>
                    <div class="text-small text-dark-gray">per month<br></br>billed monthly<br></br></div>
                  </div>
                  <a href="sign-up.html" class="button secondary w-button">Get Started</a>
                  <div class="tick-list w-richtext">
                    <ul role="list">
                      <li>Unlimited passwords</li>
                      <li>1 device</li>
                      <li>Autosave and Autofill</li>
                      <li>Password Generator</li>
                    </ul>
                  </div>
                </div>
                <div id="w-node-_4ff2cea1-00d7-f67e-15b4-82f383b0db20-b53e12e6" class="plan-card">
                  <div class="plan-card-heading">
                    <div class="plan-heading text-large text-500">Premium</div>
                    <div class="text-h2">$5.00</div>
                    <div class="text-small text-dark-gray">per month<br></br>billed monthly<br></br></div>
                  </div>
                  <a href="sign-up.html" class="button secondary w-button">Get Premium</a>
                  <div class="tick-list w-richtext">
                    <ul role="list">
                      <li>Everything in Free</li>
                      <li>Advanced password storage</li>
                      <li>Two-factor authentication</li>
                      <li>Priority customer support</li>
                      <li>Unlimited device synchronization</li>
                      <li>Backup and recovery</li>
                    </ul>
                  </div>
                </div>
                <div id="w-node-_4ff2cea1-00d7-f67e-15b4-82f383b0db38-b53e12e6" class="plan-card popular">
                  <div class="popular-tag-wrap">
                    <div class="popular-tag">
                      <div>Most Popular</div>
                      <div class="bubble-point"></div>
                    </div>
                  </div>
                  <div class="plan-card-heading">
                    <div class="plan-heading text-large text-500">Family</div>
                    <div class="text-h2">$6.00</div>
                    <div class="text-small text-dark-gray">per month<br></br>billed monthly<br></br></div>
                  </div>
                  <a href="sign-up.html" class="button w-button">Get Family</a>
                  <div class="tick-list w-richtext">
                    <ul role="list">
                      <li>Everything in Premium</li>
                      <li>Up to 6 Premium members</li>
                      <li>Secure password sharing for the whole family</li>
                      <li>Family member management</li>
                      <li>Emergency access</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <a id="w-node-_4161c9ba-d1e6-df4b-e34f-5cf5d379215a-d379215a" data-w-id="4161c9ba-d1e6-df4b-e34f-5cf5d379215a" href="#" class="arrow-link-wrap w-inline-block">
              <div class="text-500">Compare Plans</div><img src={require("./images/Right-Arrow-Green.svg")} loading="lazy" alt="" class="link-arrow" />
            </a>
          </div>
        </div>
      </div><div class="section features wf-section">
        <div class="container">
          <div class="footer-content">
            <div id="w-node-dacb1c3d-a298-1123-e63f-49f7bb3bdf1a-bb3bdee6" class="newsletter-wrap">
              <div class="newsletter-heading">
                <div class="text-large text-700">Join Our Newsletter</div>
                <p>Don&#x27;t miss out on important updates, stay connected by subscribing to our newsletter.</p>
              </div>
              <div class="form-block---subscribe w-form">
                <form id="subscribe" name="wf-form-Subscribe" data-name="Subscribe" method="get" class="form---subscribe"><input type="email" class="text-field dark w-input" maxlength="256" name="Email" data-name="Email" placeholder="Your Email" id="email-subscribe" required="" /><input type="submit" value="" data-wait="" class="submit-button w-button" /></form>
                <div class="success-message w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="error-message w-form-fail">
                  <div>Oops! Something went wrong while submitting the form.</div>
                </div>
              </div>
            </div>
            <div class="footer-line"></div>
            <div class="footer-bottom">
              <div id="w-node-b479ba1a-a1e7-5fb6-825a-62674de37573-bb3bdee6" class="footer-logo-and-social">
                <div class="footer-logo-wrap">
                  <a href="index.html" aria-current="page" class="brand w-inline-block w--current"><img src={require("./images/Logo-White---Green.svg")} loading="lazy" alt="" class="footer-logo" /></a>
                  <p>Copyright 2023 © Function Factories.</p>
                </div>
                <div class="footer-social-wrap">
                  <a href="#" class="social-link w-inline-block"><img src={require("./images/Facebook.png")} loading="lazy" alt="" /></a>
                  <a href="#" class="social-link w-inline-block"><img src={require("./images/Instagram.png")} loading="lazy" alt="" /></a>
                  <a href="#" class="social-link w-inline-block"><img src={require("./images/Youtube.png")} loading="lazy" alt="" /></a>
                  <a href="#" class="social-link w-inline-block"><img src={require("./images/Twitter.png")} loading="lazy" alt="" /></a>
                  <a href="#" class="social-link w-inline-block"><img src={require("./images/LinkedIn.png")} loading="lazy" alt="" /></a>
                </div>
              </div>
              <div class="footer-link-wrap">
                <div id="w-node-b479ba1a-a1e7-5fb6-825a-62674de37589-bb3bdee6" class="footer-link-column-wrap">
                  <div class="text-700">Product</div>
                  <a href="index.html" aria-current="page" class="footer-link w--current">Home</a>
                  <a href="#feature-section" class="footer-link">Features</a>
                  <a href="#pricing-section" class="footer-link">Pricing</a>
                </div>
                <div id="w-node-b479ba1a-a1e7-5fb6-825a-62674de37592-bb3bdee6" class="footer-link-column-wrap">
                  <div class="text-700">Resources</div>
                  <a href="contact.html" class="footer-link">Support</a>
                  <a href="get-started.html" class="footer-link">Blog</a>
                  <a href="#" class="footer-link">Privacy Policy</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><script src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=644aa0afde40afbc083e12da" type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script>
      <script src="js/webflow.js" type="text/javascript"></script></>
  );
}

export default Home;