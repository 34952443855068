import React, { useState } from "react";
import { Auth } from "aws-amplify";

export default function Signup() {
  const [errorMessage, setErrorMessage] = useState();
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();
  const [screen, setScreen] = useState("signup");
  const [code, setCode] = useState();

  async function signup() {
    try {
      console.log("signup");
      let result = await Auth.signUp({
        username: email,
        password: pass,
        attributes: {
          email,
        },
      });

      if (!result.userConfirmed) {
        setScreen("code");
      } else {
        const user = await Auth.signIn(email, pass);
        if (user) {
          window.location.href = "/login";
        }
      }
    } catch (ex) {
      setErrorMessage(ex.message);
    }
  }

  // Create a new DynamoDB object with user details
  async function createUserProfile(email) {
    const session = await Auth.currentSession();
    const user = session.getIdToken().payload;
    const idToken = session.getIdToken().getJwtToken();
    const user_details = {
      action: "create_user",
      email: email,
      username: user['cognito:username'],
      usergroup: "newusers",
      websites: {}
    }
    try {
      const response = await fetch(
        `https://74zyj58v4i.execute-api.us-east-1.amazonaws.com/prod/users`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Accept: '*/*',
            Authorization: idToken,
          },
          body: JSON.stringify(user_details),
        }
      );

      if (!response.ok) {
        console.log(response);
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  async function verify() {
    try {
      let result = await Auth.confirmSignUp(email, code);
      console.log("result: ", result);
      if (result) {
        const user = await Auth.signIn(email, pass);
        if (user) {
          let makenew = await createUserProfile(email);
          window.location.href = "/login";
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  //submit when hitting the enter key 
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      signup();
    }
  };

  return (
    <>
  <div class="login-wrap">
    <div data-animation="default" data-collapse="medium" data-duration="500" data-easing="ease-in" data-easing2="ease-out" role="banner" class="navbar-sign-up w-nav">
      <div class="container nav-sign-up">
      <a href="/" className="brand signedin w-inline-block"><img src={require("../images/SevontLogo2-p-500.png")} className ="logo" /></a>
        <div class="other-option-link">Already have an account? <a href="/login" class="login-link">Login</a>
        </div>
      </div>
    </div>
    <div class="section green wf-section">
      <div data-w-id="80fb9fd3-dd68-2679-0102-36f015384f43" class="container">
        <div class="sign-up-card">
          <div class="sign-up-heading">
            <h1 class="text-center">Get Started</h1>
          </div>
          <div class="w-form">
          {errorMessage ? <p style={{ color: "red" }}>{errorMessage}</p> : null}
          {screen === "signup" ? (
          <>
          <p class="text-dark-gray">Please create your account.</p>
            <form id="sign-up" name="wf-form-Sign-Up" data-name="Sign Up" method="get" class="form---sign-up">
              <input type="email" onChange={(e) => setEmail(e.target.value)} class="text-field w-input" maxlength="256" name="Email" data-name="Email" placeholder="Email" id="email-sign-up"/>
              <input type="password" onChange={(e) => setPass(e.target.value)} class="text-field w-input" maxlength="256" name="Password" data-name="Password" placeholder="Password" id="password-sign-up" onKeyDown={handleKeyDown} required=""/>
              <button type="button" onClick={signup} className="button w-button">Sign Up</button>
              </form></>
              ) : screen === "code" ? (
                <div>
                 <p class="text-dark-gray">Please check your email for your Verification Code</p>
                  <form>
                    <div>
                      <input 
                        className="spark-input w-input" 
                        maxLength="256" 
                        name="Validate-Form-Code" 
                        data-name="Validate-Form-Code"
                        id="SignUp-Form-Validate"
                        type="text"
                        placeholder="Code"
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </div>
                    <div>
                      <button type="button" className="button w-button" onClick={verify}>
                        Verify
                      </button>
                    </div>
                  </form>
                </div>
              ) : null}
            <div class="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
          <p class="text-dark-gray">By Signing Up, you agree to our <a href="terms-conditions.html" target="_blank">Terms &amp; Conditions</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</p>
        </div>
      </div>
    </div>
    <div class="section white wf-section">
      <div class="container">
        <div class="footer-sign-up-content">
          <p>Copyright 2023 © Function Factories.</p>
        </div>
      </div>
    </div>
  </div>
  <script src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=644aa0afde40afbc083e12da" type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script>
  <script src="js/webflow.js" type="text/javascript"></script>
  </>
  );
}