import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

export function RequireAuth({ children }) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}


//export function RequireAuth({ children }) {
//  const userauth = Auth.currentAuthenticatedUser({
//    bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
//  });
//  console.log("from the requireauth", userauth);
//  const { accessToken, idToken } = getUserSessionToken();
//    console.log("This is from the access token". accessToken);
//  //const location = useLocation();")
//  if (!userauth) {
//    return <Navigate to="/login" />;
//  }
//  return children;
//}