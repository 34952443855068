import { Auth } from 'aws-amplify';
import React, { useState } from "react";

export function ForgotPassword() {
  const [errorMessage, setErrorMessage] = useState();
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();
  const [screen, setScreen] = useState("forgotpass");
  const [code, setCode] = useState();
  

  async function resendConfirmationCode(){
    try {
      await Auth.resendSignUp(email);
      console.log('code resent successfully');
    } catch (err) {
      console.log('error resending code: ', err);
    }
  }

  async function resetpass() {
    try {
      console.log("resetpass");
      let result = await Auth.forgotPassword(email);
      console.log("Reset Password:", result);
      if (!result.userConfirmed) {
        setScreen("code");
        document.getElementById("reset-code").value = "";
        console.log(screen)
      }
    } catch (ex) {
      console.log("error", ex);
      setErrorMessage(ex.message);
    }
  }

  async function confirmReset() {
    try {
      console.log("confirmReset");
      const user = await Auth.confirmPassword(email, code, pass);
      if (user) {
        window.location.href = "/";
      }
    } catch (ex) {
      console.log("error", ex);
      setErrorMessage(ex.message);
    }
  }

    return (
      <><div class="login-wrap">
          <div data-animation="default" data-collapse="medium" data-duration="500" data-easing="ease-in" data-easing2="ease-out" role="banner" class="navbar-sign-up w-nav">
            <div class="container nav-sign-up">
            <a href="/" className="brand signedin w-inline-block"><img src={require("../images/SevontLogo2-p-500.png")} className ="logo" /></a>
              <div class="other-option-link">Don't have an account? <a href="/signup" class="login-link">Sign Up</a>
              </div>
            </div>
          </div>
          <div class="section green wf-section">
            <div data-w-id="80fb9fd3-dd68-2679-0102-36f015384f43" class="container">
              <div class="sign-up-card">
              <div class="sign-up-heading">
                <h1 class="text-center">Forgot Password</h1>
              </div>
                <div class="w-form">
                {errorMessage ? <p style={{ color: "red" }}>{errorMessage}</p> : null}
                {screen === "forgotpass" ? (
                  <form id="reset-form" name="wf-form-Reset" data-name="Reset" method="get" class="form---sign-up">
                    <label htmlFor="Forgot-Form-Email">Email Address</label>
                    <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} class="text-field w-input" maxlength="256" name="forgot-form-email" data-name="Forgot-Form-Email" placeholder="Enter Your Email Address" id="Forgot-Form-Email" required="" />
                    <button type="button" onClick={resetpass} className="button w-button">Submit</button>
                    </form>
                    ) : screen === "code" ? (
                      <form id="confirm-form" name="wf-form-Confirm" data-name="Confirmation" method="get" class="form---sign-up">
                      <label htmlFor="Reset-Code">Validation code:</label>
                      <input type="text" onChange={(e) => setCode(e.target.value)} value={email} class="text-field w-input" maxlength="256" name="Reset-Code" data-name="Reset-Code" placeholder="012345" id="reset-code" required="" />
                      <label htmlFor="Reset-Password">New Password</label>
                      <input type="password" onChange={(e) => setPass(e.target.value)} value={pass} class="text-field w-input" maxlength="256" name="Reset-Password" data-name="Reset-Password" placeholder="Password" id="reset-password" required="" />
                      <button type="button" onClick={confirmReset} id="Reset-Submit" className="button w-button">Submit</button>
                      <div className="spark-form-sub-text Login">Didn't receive an email with the code? <a onClick={resendConfirmationCode} className="spark-text-link spark-gray">Click here to resend</a>
                      </div>
                      </form>
                      ) : null}
                </div>
                <div class="other-option-link">Don't have an account? <a href="/signup" class="login-link">Sign Up</a>
              </div>
              </div>
            </div>
          </div>
          <div class="section white wf-section">
            <div class="container">
              <div class="footer-sign-up-content">
              <p>Copyright 2023 © Function Factories.</p>
              </div>
            </div>
          </div>
        </div></>
      
    )
}
